.quillEditor ol {
  font-size: 13px;
  font-family: "Area-Normal-Bold" !important;
  // font-style: normal;
  color: #194049;
  padding: 20px;
}

.quillEditor ol li {
  list-style-type: none !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 13px;
  font-family: "Area-Normal-Bold" !important;
  font-style: normal;
  color: #263238;
  position: relative;
}

.quillEditor span {
  font-size: 13px;
  font-family: "Area-Normal-Black" !important;
  font-style: normal;
  color: #194049;
}

.quillEditor ul {
  font-size: 13px;
  font-family: "Area-Normal-Black" !important;
  font-style: normal;
  color: #194049;
}

.quillEditor ul li {
  font-size: 13px;
  font-family: "Area-Normal-Semibold" !important;
  font-style: normal;
  color: #194049;
}

.quillEditor h1 {
  font-size: 2em;
  font-family: "Columbia-Sans" !important;
  font-style: normal;
  text-align: center;
  color: #194049;
}
.ql-editor .ql-align-center {
  text-align: center;
}

.quillEditor h3 {
  font-size: 14px;
  font-family: "Area-Extended" !important;
  font-style: normal;
  color: #194049;
}

.quillEditor strong {
  font-size: 14px;
  font-family: "Area-Normal-Semibold" !important;
  font-style: normal;
  color: #194049;
}

.quillEditor p {
  font-size: 13px;
  font-family: "Area-Normal-Semibold" !important;
  font-style: normal;
  color: #194049;
}

.quillEditor strong {
  font-size: 14px;
  font-family: "Area-Normal-Black" !important;
  font-style: normal;
  color: #194049;
}

.quillEditor code {
  font-size: 13px;
  font-family: "Area-Normal-Bold" !important;
  font-style: normal;
  color: #194049;
}

.quillEditor .ql-indent-1:not(.ql-direction-rtl) {
  margin-left: 10px !important;
  list-style: none !important;
  font-family: 'Area-Normal', sans-serif;
  font-style: normal;
}

.ql-editor .ql-indent-1::before:not(.ql-direction-rtl)  {
  display: none !important;
  list-style: none !important;

  }

.quillEditor .ql-indent-2:not(.ql-direction-rtl) {
  margin-left: 30px !important;
  font-family: 'Area-Normal', sans-serif;
  font-style: normal;
}

.ql-editor .ql-indent-2::before:not(.ql-direction-rtl)  {
  display: list-item;
  }

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  margin-left: 50px !important;
  list-style: disc !important;
  display: list-item;
  font-size: 13px;
  font-family: 'Area-Normal', sans-serif;
  font-style: normal;
}

.ql-editor .ql-indent-4::before {
display: none;
}

// {list-style-type: circle;}
// {list-style-type: square;}
// {list-style-type: upper-roman;}
// {list-style-type: lower-alpha;}

.quillEditor .ql-video {
  width: 100% !important;
  height: 500px !important;
}

.quillEditor .ql-image {
  width: 100% !important;
  height: 300px !important;
}

/* Editor styling */
.quillEditor .ql-container {
  border-bottom-left-radius: 0.5em !important;
  border-bottom-right-radius: 0.5em !important;
  margin: 0 auto; /* Center horizontally */
  background: #fff;
}

.quillEditor .ql-snow.ql-toolbar {
  display: block;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.quillEditor blockquote {
  font-size: 13px;
  width: 80%;
  margin: 10px auto;
  font-family: "Area-Normal-Semibold";
  font-style: italic;
  color: #194049;
  padding: 1.2em 30px 1.2em 75px;
  border-left: 8px solid #ab47bc;
  line-height: 1.6;
  position: relative;
  background: #f5f5f5;
}

.quillEditor blockquote::before {
  font-family: Arial;
  content: "\201C";
  color: #ab47bc;
  font-size: 4em;
  position: absolute;
  left: 10px;
  top: -10px;
}

.quillEditor blockquote::after {
  content: "";
}

.quillEditor blockquote span {
  display: block;
  color: #194049;
  font-style: italic;
  font-weight: bold;
  margin-top: 1em;
}
